<template>
  <v-card width="95%" style="margin: 15px auto" v-if="procLoaded">
    <v-card-title class='primary white--text justify-center'>
      Отчет по тех. процессу: {{ proc.template.name }}
    </v-card-title>
    <v-card-text>
      <div class="title text-center pt-4">Сводная информация и статистика</div>
      <v-simple-table dense class="table-last-column-right">
          <tbody>
            <tr>
              <td>Индификатор группы</td>
              <td>{{proc.template.group}}</td>
            </tr>
            <tr>
              <td>Уникальный идентификатор
              <td>{{proc.uuid}}</td>
            </tr>
            <tr>
              <td>Серийный номер</td>
              <td>{{proc.serial}}</td>
            </tr>
            <tr>
              <td>Дата начала</td>
              <td>{{secToDate(proc.startedAt)}}</td>
            </tr>
            <tr>
              <td>Время начала</td>
              <td>{{secToTime(proc.startedAt)}}</td>
            </tr>
            <tr>
              <td>Дата окончания</td>
              <td>{{secToDate(proc.finishedAt)}}</td>
            </tr>
            <tr>
              <td>Время окончания</td>
              <td>{{secToTime(proc.finishedAt)}}</td>
            </tr>
            <tr>
              <td>Общее время выполнения</td>
              <td>{{secToTime(proc.finishedAt - proc.startedAt)}}</td>
            <tr>
              <td>Время сварки (чистое время горения сварочной дуги);</td>
              <td>{{secToTime(proc.weldTime / 1000)}}</td>
            </tr>
            <tr>
              <td>Время дополнительных операций</td>
              <td>
                {{secToTime((proc.finishedAt - proc.startedAt) - (proc.weldTime) / 1000)}}
              </td>
            </tr>
          </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Суммарные отклонения</div>
      <v-treeview
        class="bottom-border" 
        :items="procDevs" 
        :open-on-click="true" 
        >
      </v-treeview>
      <div class="title text-center pt-4">Поканальные отклонения</div>
      <template v-for="chDevs in channelsDevs">
        <div 
          class="my-4 px-2 bottom-border"
          :key="chDevs[0].channelId + ' ' + chDevs[0].id"
          >
          <div class="subheader text-center">{{getChannelNameById(chDevs[0].channelId)}}</div>
          <v-treeview 
            :items="chDevs" 
            :open-on-click="true" 
            >
          </v-treeview>
        </div>
      </template>
      <div class="title text-center pt-4">
        Индикаторные отклонения для шва №{{indiSeam}}, стыка №{{indiCode}}, слоя №{{indiLayerIndx + 1}}
      </div>
      <v-simple-table class="text-center">
        <thead>
          <tr>
            <td colspan="3">По карте</td>
            <td colspan="3">По слою</td>
          </tr>
          <tr>
            <td>доп</td>
            <td>крит</td>
            <td>общ</td>
            <td>доп</td>
            <td>крит</td>
            <td>общ</td>
          </tr>
        </thead>
        <tbody>
            <td>
            {{toPercentString(
              proc.processCards[indiCardIndx].devAllow, 
              proc.processCards[indiCardIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              proc.processCards[indiCardIndx].devCrit, 
              proc.processCards[indiCardIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              proc.processCards[indiCardIndx].devCommon, 
              proc.processCards[indiCardIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              proc.processCards[indiCardIndx].layers[indiLayerIndx].devAllow, 
              proc.processCards[indiCardIndx].layers[indiLayerIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              proc.processCards[indiCardIndx].layers[indiLayerIndx].devCrit, 
              proc.processCards[indiCardIndx].layers[indiLayerIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              proc.processCards[indiCardIndx].layers[indiLayerIndx].devCommon, 
              proc.processCards[indiCardIndx].layers[indiLayerIndx].weldTime
            )}}</td>
        </tbody>
      </v-simple-table>
      <div class="title text-center">По каналам</div>
      <template v-for="chDevs in channelsIndicators">
        <div 
          class="my-4 px-2 bottom-border"
          :key="chDevs[0].channelId + 'indi' + chDevs[0].id" 
          >
          <div class="subheader text-center">{{getChannelNameById(chDevs[0].channelId)}}</div>
          <v-treeview 
            :items="chDevs" 
            :open-on-click="true" 
            >
          </v-treeview>
        </div>
      </template>
      <div class="title text-center">Карты тех. процесса</div>
      <v-simple-table class="table-text-center" dense>
        <thead>
          <tr>  
            <th>ID карты</th>
            <th>№ шва</th>
            <th>№ стыка</th>
            <th>Начата</th>
            <th>Закончена</th>
            <th>Время выполнения</th>
            <th>Время сварки</th>
            <th>Доп. откл.</th>
            <th>Крит. откл.</th>
            <th>Общ. откл.</th>
          </tr>  
         </thead>
        <tbody>
          <tr 
            v-for="procCard in proc.processCards" 
            @click="goToViewProcCard(procCard.id)" 
            style="cursor: pointer;"
            >
            <td>{{procCard.card.name}}</td>
            <td>{{procCard.seam }}</td>
            <td>{{procCard.code }}</td>
            <td>
              <div>{{secToDate(procCard.startedAt)}}</div>
              <div>{{secToTime(procCard.startedAt)}}</div>
            </td>
            <td>
              <div>{{secToDate(procCard.finishedAt)}}</div>
              <div>{{secToTime(procCard.finishedAt)}}</div>
            </td>
            <td>{{secToHoursTime(procCard.finishedAt - procCard.startedAt)}}</td>
            <td>{{secToHoursTime(procCard.weldTime/1000)}}</td>
            <td>{{toPercentString(procCard.devAllow, procCard.weldTime)}}</td>
            <td>{{toPercentString(procCard.devCrit, procCard.weldTime)}}</td>
            <td>{{toPercentString(procCard.devCommon, procCard.weldTime)}}</td>
          </tr>
        </tbody>
      </v-simple-table>   
    </v-card-text>
  </v-card>
</template>

<script>
import { secToDate } from '@/libs/time.js'
import { secToTime } from '@/libs/time.js'
import { secToHoursTime } from '@/libs/time.js'
import { prepareDevs } from '../../libs/helpers.js'
import { getChannelNameById } from '@/libs/common.js'
import { toPercentString } from '@/libs/common.js'
import { mapState } from 'vuex'

export default {
  components: {},
  data: () => ({
    secToTime,
    secToDate,
    secToHoursTime,
    prepareDevs,
    getChannelNameById,
    toPercentString,
    procLoaded: false,
    proc: {},
    procDevs: [],
    channelsDevs: [],
    indiLayerIndx: 0,
    indiCardIndx: 0,
    indiSeam: '',
    indiCode: '',
  }),
  computed: {
    ...mapState('common', { modules: state => state.modules }),       
    channelsIndicators() {
      let lCommon = 0, res =[]
      this.proc.processCards.forEach((c, i) => {
        c.layers.forEach((l, j) => {
        if ((Number(l.devCommon) / Number(l.weldTime)) > lCommon) {
            this.indiCardIndx = i
            this.indiLayerIndx = j
            this.indiSeam = c.seam
            this.indiCode = c.code
            lCommon = Number(l.devCommon) / Number(l.weldTime)
          }
        })
      })
      let l = this.proc.processCards[this.indiCardIndx].layers[this.indiLayerIndx]
      l.channelsDeviations.forEach(chDevs => {
        res.push([Object.assign(
          {}, 
          this.prepareDevs(chDevs, l.weldTime), 
          { channelId: chDevs.channelId }
        )])
      })
      return res
    }           
  },
  methods: {
    goToViewProcCard(id) {
      this.$router.push('/manufacture/process-card/view?id=' + id)
    },
  },
  mounted: function() {
    this.$axios.get('manufacture/process/view', { 
      params: { procId: this.$route.query.id } 
    }).then(res => {
      this.proc = res.data
      this.procDevs.push(this.prepareDevs(this.proc, this.proc.weldTime))
      this.proc.channelsDeviations.forEach(chDevs => {
        this.channelsDevs.push([Object.assign(
          {}, 
          this.prepareDevs(chDevs, this.proc.weldTime), 
          { channelId: chDevs.channelId }
        )])
      })
      this.procLoaded = true      
    }).catch(() => {}).finally(() => {})
  },
}
</script>

<style>
  .table-last-column-right tbody tr td:last-child {
    text-align: right;
  }
  .table-text-center {
    text-align: center;
  }
  .table-text-center th {
    text-align: center !important;
  }
  .bottom-border {
    border-bottom-style: solid;
    border-bottom-width: thin;    
  }  
</style>